:root {
  --nav-links: cyan;
  --background: #333;
  --panel-background: #444;
  --border-color: #666;
  --item-background: #666;
  --item-hover-background: #888;
  --item-hover-shadow: #999;
  --github-color: #5d0093;
  --linkedin-color: #2867b2;
  --discord-color: #738adb;
  --email-color: #d44638;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: white;
  font-size: 16px;
  background: var(--background);
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  min-width: 320px;
}

/* Override */

a {
  color: inherit;
  text-decoration: none;
}

/* Utility */

.container {
  width: 90%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 50px;
}

.container-thin {
  width: 70%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 50px;
}

.btn {
  font: inherit;
  border-radius: 20px;
  border-style: none;
  outline: none;
  cursor: pointer;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

/* Used for keeping the footer at the bottom of the page */
#page-container {
  position: relative;
  min-height: 100vh;
}

/* Navbar */

.navbar {
  padding: 10px;
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  z-index: 2;
  color: var(--nav-links);
}

.navbar-solid {
  background: rgba(51, 51, 51, 0.7);
}

.nav-item {
  margin: 0 10px;
  font-size: 2rem;
  background: none;
  color: inherit;
  border-radius: 0;

  /* Underline animation for hover */
  background-image: linear-gradient(var(--nav-links), var(--nav-links));
  background-size: 0 3px, cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.15s ease-out;
}

.nav-item:hover {
  background-size: 100% 3px, auto;
}

.logo {
  color: inherit;
  background: none;
  margin: 0 10px;
  font-weight: bold;
  font-size: 3rem;
  cursor: default;
}

/* Home page */

.hero {
  height: 100vh;
  width: 100vw;
  position: relative;
  /* background-size: cover;
  background-position: 50% 50%; */
  background-color: var(--background);
  /* background-image: url('./images/hero.jpg'); */
}

.particles-js {
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: var(--background);
}

.overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0, 0.65); */
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-content > h1,
.home-content > h4 {
  max-width: 600px;
  margin-bottom: 50px;
  text-align: center;
}

#main-header {
  font-size: 4rem;
}

#secondary-header {
  font-size: 1.6rem;
}

.continue-btn {
  font-size: 1.2rem;
  font-weight: bold;
  border: 3px solid #777;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  transition: background-color 0.15s;
}

.continue-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.15s;
}

/* Projects and Timeline page */

.projects-timeline {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  overflow-y: visible;
}

.timeline,
.projects {
  flex: 1;
  background: var(--panel-background);
  margin-bottom: 50px;
}

.section-title {
  display: block;
  text-align: center;
  margin: 10px 0 20px;
}

/* Timeline Component (uses library) */

/* Overwriting spacing from the vertical timeline library */
.vertical-timeline {
  width: 95% !important;
  margin-bottom: 20px;
}

/* Adding more spacing for the timeline item dates */
.tl-item-date-left {
  padding-left: 20px !important;
}

.tl-item-date-right {
  padding-right: 20px !important;
}

/* Projects Component */

#projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
}

.project-link-container {
  margin-bottom: 20px;
  font-family: inherit;
  color: white;
  font-size: inherit;
  border: none;
  background: none;
  text-align: left;
}

.project-item {
  display: flex;
  width: 100%;
  padding: 1em;
  transition: 0.25s;
  background-color: var(--item-background);
  border-radius: 10px;
}

.project-item:hover {
  cursor: pointer;
  background-color: var(--item-hover-background);
  box-shadow: 0 0 8px 3px var(--item-hover-shadow);
}

.project-img {
  margin-right: 20px;
  align-self: center;
  width: 128px;
  height: 128px;
}

.project-content {
  width: 100%;
}

.project-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.project-title {
  text-align: left;
  font-size: 1.6rem;
}

.project-link {
  text-align: left;
  color: var(--nav-links);
}

.project-date {
  font-size: 1.2rem;
}

.project-technologies-list {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.project-technologies-list p {
  margin-right: 20px;
  font-style: italic;
  font-size: 1.2rem;
}

.project-description {
  margin-top: 10px;
}

/* About Page */

.about-flex {
  display: flex;
  flex-direction: column;
}

.about-primary {
  background-color: var(--panel-background);
  padding: 20px;
  margin-bottom: 50px;
}

.about-primary-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-primary h1 {
  text-align: center;
}

#portrait {
  width: 150px;
  height: 150px;
  margin: 20px 0;
  border-radius: 50%;
}

#bio {
  display: block;
  font-size: 1.2rem;
  text-align: center;
}

#bio p {
  padding-bottom: 10px;
}

#bio p:last-of-type {
  padding-bottom: 0;
}

.about-secondary {
  background-color: var(--panel-background);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.about-secondary * {
  flex: 1;
  text-align: center;
}

.about-secondary #about-a,
.about-secondary #about-b {
  padding-right: 9px;
  border-right: 2px solid var(--border-color);
  border-right-style: dotted;
  margin-right: 9px;
}

.about-secondary-header {
  padding-bottom: 20px;
}

.about-list {
  list-style: none;
}

.about-list li {
  padding: 5px 0;
  border-bottom: 2px dotted var(--border-color);
}

.about-list li:last-of-type {
  border-bottom: none;
}

/* Footer */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--background);
}

.footer-container {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
}

.footer-item {
  font-family: inherit;
  color: white;
  font-size: inherit;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

#footer-banner-wrapper {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

#discord-banner {
  background-color: var(--discord-color);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  margin-left: 60px;
  white-space: nowrap;
}

/* Footer Icons */

#github-icon:hover {
  color: var(--github-color);
}

#linkedin-icon:hover {
  color: var(--linkedin-color);
}

#discord-icon:hover {
  color: var(--discord-color);
}

#email-icon:hover {
  color: var(--email-color);
}

/* Page animations */

.page-transition-enter {
  opacity: 0;
}

.page-transition-enter.page-transition-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.page-transition-leave {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

.page-transition-leave.page-transition-leave-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

/* Misc Media queries */

/* Stack the timeline and projects list */
@media only screen and (max-width: 1750px) {
  .projects-timeline {
    padding-top: 100px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: visible;
  }

  .timeline {
    margin-bottom: 50px;
  }
}

/* Stop showing project images */
@media only screen and (max-width: 930px) {
  .project-img {
    display: none;
  }

  .about-primary {
    margin-bottom: 20px;
  }

  .about-secondary {
    flex-direction: column;
    justify-content: flex-start;
    background-color: inherit;
    box-shadow: none;
    padding: 0;
  }

  .about-secondary #about-a,
  .about-secondary #about-b {
    border: none;
    margin-right: 0;
  }

  .about-secondary div {
    background-color: var(--panel-background);
    margin-bottom: 20px;
    padding: 20px !important;
  }

  #main-header {
    font-size: 2rem;
  }

  #secondary-header {
    font-size: 1rem;
  }

  .home-content > h1,
  .home-content > h4 {
    max-width: 400px;
    margin-bottom: 50px;
    text-align: center;
  }
}

/* Phone (started at 600px because projects were getting crammed) */
@media only screen and (max-width: 600px) {
  .container-thin {
    width: 85%;
  }

  .home-content > h1,
  .home-content > h4 {
    max-width: 300px;
    margin-bottom: 50px;
    text-align: center;
  }

  .project-header {
    flex-direction: column;
  }

  .project-title,
  .project-link {
    text-align: center;
  }

  .project-technologies-list {
    justify-content: space-evenly;
  }

  .project-technologies-list p {
    margin: 0 5px;
  }

  .navbar {
    justify-content: space-evenly;
  }

  .logo {
    display: none;
  }

  .nav-item {
    font-size: 24px;
  }

  .project-description,
  #bio,
  .about-secondary {
    font-size: 0.8rem;
  }
}
